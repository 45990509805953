import React from 'react'
import styled from 'styled-components'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { SET_USER } from '../../../reducers/AppReducer';
import Loading from '../../common/Loading';

const Wrapper = styled.div`
    margin-right: 200px;
`

const LoadingWrapper = styled.div`
    height: 80vh;
    width: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function Grid() {

    const { appState, dispatch } = useContext(AppContext);
    const [data, setData] = useState([])
    //TODO: Check if it makes sense to keep this
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [gridApi, setGridApi] = useState(null);



    const apiUrl = process.env.GATSBY_CEMEAPI_ENDPOINT;
    // const apiUrl = 'http://localhost:9000';
    // const apiUrl = 'https://ceme-api-xeu74yuiwq-ez.a.run.app'

    useEffect(() => {
        try {
            const getData = async () => {
                const options = {

                }
                const response = await axios.get(`${apiUrl}/api/consumptions/all`, {
                    // params: {
                    //     page: 0,
                    //     size: 100,
                    // },
                    headers: {
                        authorization: appState.user.accessToken,
                        refresh_token: appState.user.refreshToken
                    }
                })
                if (response.headers.access_token && response.headers.refresh_token) {
                    dispatch({ type: SET_USER, payload: { ...appState.user, accessToken: response.headers.access_token, refreshToken: response.headers.refresh_token } })
                }
                setData(response.data)
            }

            getData()

        } catch (e) {
            console.error(e)
        }
    }, [])


    const defaultColDef = {
        filter: true,
        resizable: true,
        sortable: true,
    }

    const onGridReady = (params) => {
        setGridColumnApi(params.columnApi);
        setGridApi(params.api);
    }

    const onFirstDataRendered = (params) => {
        autoSizeAll(params)
    };

    const autoSizeAll = (params) => {
        params.columnApi.autoSizeAllColumns();
    };

    const dateFilterParams = {
        // provide comparator function
        comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var ddmmyyyyDate = dateAsString.split('T');
            var dateParts = ddmmyyyyDate[0].split('-');
            var cellDate = new Date(
                Number(dateParts[0]),
                Number(dateParts[1]) - 1,
                Number(dateParts[2])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
        },
        browserDatePicker: true,
    };

    const getDate = params => {
        const dateString = params.value
        const year = dateString.substr(0, 4)
        const month = dateString.substr(5, 2)
        const day = dateString.substr(8, 2)
        const hour = dateString.substr(11, 2)
        const min = dateString.substr(14, 2)
        const sec = dateString.substr(17, 2)

        // console.log(`${year}-${month}-${day} ${hour}:${min}:${sec}`)
        return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    }

    const getkWh = params => {
        return `${params.value} kWh`
    }

    const getPrice = params => {
        return `€${params.value}`
    }

    return (
        <Wrapper>
            <div className="ag-theme-alpine" style={{ paddingTop: '70px', height: 'calc(100vh - 70px)' }}>
                {data.length > 0 ?
                    <AgGridReact
                        rowData={data}
                        defaultColDef={defaultColDef}
                        ensureDomOrder={true}
                        enableCellTextSelection={true}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridReady={onGridReady}
                    >
                        <AgGridColumn maxWidth={400} headerName="Informação Base" field="idUsage">
                            <AgGridColumn maxWidth={400} headerName="ID Utilização" field="idUsage"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="ID Carregamento" field="idSubUsage"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="ID Cartão" field="idExternalNumber"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Data Início" field="startTimestamp" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Data Fim" field="stopTimestamp" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                        </AgGridColumn>
                        <AgGridColumn maxWidth={400} headerName="Energia" field="idUsage" valueFormatter={getkWh}>
                            <AgGridColumn maxWidth={400} headerName="Energia Vazio" field="energia_vazio" valueFormatter={getkWh}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Energia Fora Vazio" field="energia_fora_vazio" valueFormatter={getkWh}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Energia Total" field="energia_total_periodo" valueFormatter={getkWh}></AgGridColumn>
                        </AgGridColumn>
                        <AgGridColumn maxWidth={400} headerName="Custos OPC" field="idUsage">
                            <AgGridColumn maxWidth={400} headerName="Preço OPC" field="preco_opc"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário OPC Ativação" field="preco_unitario_opc_ativacao" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário OPC Energia" field="preco_unitario_opc_energia" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário OPC Tempo" field="preco_unitario_opc_tempo" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço OPC Ativação" field="preco_opc_ativacao" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço OPC Energia" field="preco_opc_energia" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço OPC Tempo" field="preco_opc_tempo" valueFormatter={getPrice}></AgGridColumn>
                        </AgGridColumn>
                        <AgGridColumn maxWidth={400} headerName="Tarifa Acesso Redes " field="idUsage">
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário Acesso Redes Vazio" field="preco_unitario_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário Acesso Redes Fora Vazio" field="preco_unitario_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Unitário Acesso Redes Vazio" field="desconto_unitario_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Unitário Acesso Redes Fora Vazio" field="desconto_unitario_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário c/ Desconto Acesso Redes Vazio" field="preco_unitario_com_desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário c/ Desconto Acesso Redes Fora Vazio" field="preco_unitario_com_desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Acesso Redes Vazio" field="desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Acesso Redes Fora Vazio" field="desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Sem Desconto Acesso Redes Vazio" field="preco_sem_desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Sem Desconto Acesso Redes Fora Vazio" field="preco_sem_desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Com Desconto Acesso Redes Vazio" field="preco_com_desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Com Desconto Acesso Redes Fora Vazio" field="preco_com_desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Acesso Redes" field="desconto_acesso_redes" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Sem Desconto Acesso Redes" field="preco_sem_desconto_acesso_redes" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Com Desconto Acesso Redes" field="preco_com_desconto_acesso_redes" valueFormatter={getPrice}></AgGridColumn>
                        </AgGridColumn>
                    </AgGridReact>
                    : <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                    }
            </div>
        </Wrapper>
    )
}
