import React from 'react'
import styled from 'styled-components'
import ConsumptionStripe from './ConsumptionStripe'

const Wrapper = styled.div`
    background-color: #f9f9f9;
`

const YearContainer = styled.div`
    padding: 40px 60px;
`

const MonthContainer = styled.div`
    padding: 0px 40px 40px 0;
`

const Year = styled.div`
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 20pt;
    padding: 10px 0;
    color: ${props => props.theme.default.colors.primary};
    position: sticky;
    top: 70px;
    &::before {
        content: '';
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        background: rgb(251 251 251 / 93%);
        z-index: -999;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
    }
`

const Month = styled.div`
    margin-bottom: 10px;
    font-size: 14pt;
    font-weight: 500;
    text-transform: uppercase;
    position: sticky;
    top: 126px;
    padding: 10px 0;

    &::before {
        content: '';
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        background: rgb(251 251 251 / 93%);
        z-index: -999;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
    }
`

const getYearArray = (_data) => {
    const sortedArray = Object.keys(_data)
    sortedArray.sort().reverse()
    return sortedArray
}


export default function ConsumptionList({data}) {
    return (
        <Wrapper>
            { getYearArray(data).map(
                year => <YearContainer key={year}>
                    <Year>{year}</Year>
                    { Object.keys(data[year]).map(
                        month => <MonthContainer key={month}>
                            <Month>{month}</Month>
                            {data[year][month].map((row, i) => <ConsumptionStripe key={i} data={row} />)}
                        </MonthContainer>
                    )}
            </YearContainer>

            )}
        </Wrapper>
    )
}
