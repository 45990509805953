import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import ConsumptionList from './sub/ConsumptionList';
import styled from 'styled-components'

const Container = styled.div`
    padding: 40px;
`

export default function AccountDataVis() {

    const { appState, dispatch } = useContext(AppContext);
    const [data, setData] = useState([])
    const [apiData, setAPIData] = useState([])
    const apiUrl = process.env.GATSBY_CEMEAPI_ENDPOINT;
    // const apiUrl = 'http://localhost:9000';

    useEffect(() => {
        // try {
        //     const getData = async () => {
        //         const response = await axios.get(`${apiUrl}/api/consumptions/all`, {
        //             headers: {
        //                 authorization: appState.user.accessToken,
        //                 refresh_token: appState.user.refreshToken
        //             }
        //         })
        //         if(response.headers.access_token && response.headers.refresh_token) {
        //             dispatch({type: SET_USER, payload: {...appState.user, accessToken: response.headers.access_token, refreshToken: response.headers.refresh_token}})
        //         }
        //         setAPIData(response.data.consumptions)
        //     }
        //     getData()
        // } catch (e) {
        //     console.error(e)
        // }

        const mockData = [
            {
                "idUsage": "BRG-00033-01-1578501922",
                "idSubUsage": "BRG-00033-01-1578501922-1",
                "idExternalNumber": "PTEVAZ00000010000093005",
                "startTimestamp": "2020-01-08T16:40:47.000Z",
                "stopTimestamp": "2020-01-08T17:34:56.000Z",
                "idNetworkOperator": "HORZ",
                "energia_total_periodo": 9.489,
                "preco_opc": 2.71,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.05,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 2.71,
                "energia_vazio": 0,
                "energia_fora_vazio": 9.489,
                "preco_unitario_acesso_redes_vazio": 0.0325,
                "preco_unitario_acesso_redes_fora_vazio": 0.0811,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0325,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0566,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.23,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0.77,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.54,
                "desconto_acesso_redes": 0.23,
                "preco_sem_desconto_acesso_redes": 0.77,
                "preco_com_desconto_acesso_redes": 0.54
            },
            {
                "idUsage": "BRG-00032-02-1577963288",
                "idSubUsage": "BRG-00032-02-1577963288-1",
                "idExternalNumber": "PTEVAZ00000010000093002",
                "startTimestamp": "2020-01-02T11:02:01.000Z",
                "stopTimestamp": "2020-01-02T11:41:00.000Z",
                "idNetworkOperator": "HORZ",
                "energia_total_periodo": 0.01,
                "preco_opc": 1.95,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.05,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 1.95,
                "energia_vazio": 0,
                "energia_fora_vazio": 0.01,
                "preco_unitario_acesso_redes_vazio": 0.0325,
                "preco_unitario_acesso_redes_fora_vazio": 0.0811,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0325,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0566,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0,
                "desconto_acesso_redes": 0,
                "preco_sem_desconto_acesso_redes": 0,
                "preco_com_desconto_acesso_redes": 0
            },
            {
                "idUsage": "BRG-00033-01-1577723050",
                "idSubUsage": "BRG-00033-01-1577723050-1",
                "idExternalNumber": "PTEVAZ00000010000093005",
                "startTimestamp": "2019-12-30T16:20:28.000Z",
                "stopTimestamp": "2019-12-30T16:49:47.000Z",
                "idNetworkOperator": "HORZ",
                "energia_total_periodo": 5.299,
                "preco_opc": 1.47,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.05,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 1.47,
                "energia_vazio": 0,
                "energia_fora_vazio": 5.299,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.13,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0.56,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.43,
                "desconto_acesso_redes": 0.13,
                "preco_sem_desconto_acesso_redes": 0.56,
                "preco_com_desconto_acesso_redes": 0.43
            },
            {
                "idUsage": "BRG-00032-02-1577704725",
                "idSubUsage": "BRG-00032-02-1577704725-1",
                "idExternalNumber": "PTEVAZ00000010000093005",
                "startTimestamp": "2019-12-30T11:13:12.000Z",
                "stopTimestamp": "2019-12-30T12:13:16.000Z",
                "idNetworkOperator": "HORZ",
                "energia_total_periodo": 6.34,
                "preco_opc": 3,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.05,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 3,
                "energia_vazio": 0,
                "energia_fora_vazio": 6.34,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.16,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0.67,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.51,
                "desconto_acesso_redes": 0.16,
                "preco_sem_desconto_acesso_redes": 0.67,
                "preco_com_desconto_acesso_redes": 0.51
            },
            {
                "idUsage": "BRG-00032-02-1576866704",
                "idSubUsage": "BRG-00032-02-1576866704-1",
                "idExternalNumber": "PTEVAZ00000010000093002",
                "startTimestamp": "2019-12-20T18:27:03.000Z",
                "stopTimestamp": "2019-12-20T18:27:27.000Z",
                "idNetworkOperator": "HORZ",
                "energia_total_periodo": 0,
                "preco_opc": 0,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 0,
                "energia_vazio": 0,
                "energia_fora_vazio": 0,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0,
                "desconto_acesso_redes": 0,
                "preco_sem_desconto_acesso_redes": 0,
                "preco_com_desconto_acesso_redes": 0
            },
            {
                "idUsage": "ETR-00002-02-1560105485",
                "idSubUsage": "ETR-00002-02-1560105485-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-09T19:38:02.000Z",
                "stopTimestamp": "2019-06-09T20:16:13.000Z",
                "idNetworkOperator": "EDPC",
                "energia_total_periodo": 32.34,
                "preco_opc": 3.06,
                "preco_unitario_opc_ativacao": 0.15,
                "preco_unitario_opc_energia": 0.09,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0.15,
                "preco_opc_energia": 2.91,
                "preco_opc_tempo": 0,
                "energia_vazio": 0,
                "energia_fora_vazio": 32.34,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.79,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 3.42,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 2.63,
                "desconto_acesso_redes": 0.79,
                "preco_sem_desconto_acesso_redes": 3.42,
                "preco_com_desconto_acesso_redes": 2.63
            },
            {
                "idUsage": "ETR-00001-02-1560083967",
                "idSubUsage": "ETR-00001-02-1560083967-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-09T13:39:21.000Z",
                "stopTimestamp": "2019-06-09T13:52:11.000Z",
                "idNetworkOperator": "EDPC",
                "energia_total_periodo": 9.72,
                "preco_opc": 1.02,
                "preco_unitario_opc_ativacao": 0.15,
                "preco_unitario_opc_energia": 0.09,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0.15,
                "preco_opc_energia": 0.87,
                "preco_opc_tempo": 0,
                "energia_vazio": 0,
                "energia_fora_vazio": 9.72,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.24,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 1.03,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.79,
                "desconto_acesso_redes": 0.24,
                "preco_sem_desconto_acesso_redes": 1.03,
                "preco_com_desconto_acesso_redes": 0.79
            },
            {
                "idUsage": "ETR-00001-02-1560083867",
                "idSubUsage": "ETR-00001-02-1560083867-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-09T13:37:42.000Z",
                "stopTimestamp": "2019-06-09T13:37:46.000Z",
                "idNetworkOperator": "EDPC",
                "energia_total_periodo": 0,
                "preco_opc": 0,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0.09,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 0,
                "energia_vazio": 0,
                "energia_fora_vazio": 0,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0,
                "desconto_acesso_redes": 0,
                "preco_sem_desconto_acesso_redes": 0,
                "preco_com_desconto_acesso_redes": 0
            },
            {
                "idUsage": "MTS-00007-02-1559997188",
                "idSubUsage": "MTS-00007-02-1559997188-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-08T13:32:45.000Z",
                "stopTimestamp": "2019-06-08T14:07:40.000Z",
                "idNetworkOperator": "KLCO",
                "energia_total_periodo": 24.621,
                "preco_opc": 2.79,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.08,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 2.79,
                "energia_vazio": 0,
                "energia_fora_vazio": 24.621,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.6,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 2.6,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 2,
                "desconto_acesso_redes": 0.6,
                "preco_sem_desconto_acesso_redes": 2.6,
                "preco_com_desconto_acesso_redes": 2
            },
            {
                "idUsage": "MTS-00007-02-1559736633",
                "idSubUsage": "MTS-00007-02-1559736633-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-05T13:10:24.000Z",
                "stopTimestamp": "2019-06-05T14:58:18.000Z",
                "idNetworkOperator": "KLCO",
                "energia_total_periodo": 51.37,
                "preco_opc": 8.63,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.08,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 8.63,
                "energia_vazio": 0,
                "energia_fora_vazio": 51.37,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 1.26,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 5.43,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 4.17,
                "desconto_acesso_redes": 1.26,
                "preco_sem_desconto_acesso_redes": 5.43,
                "preco_com_desconto_acesso_redes": 4.17
            },
            {
                "idUsage": "MTS-00007-02-1559735981",
                "idSubUsage": "MTS-00007-02-1559735981-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-05T12:59:29.000Z",
                "stopTimestamp": "2019-06-05T13:07:53.000Z",
                "idNetworkOperator": "KLCO",
                "energia_total_periodo": 6.8,
                "preco_opc": 0.67,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0.08,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 0.67,
                "energia_vazio": 0,
                "energia_fora_vazio": 6.8,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.17,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0.72,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.55,
                "desconto_acesso_redes": 0.17,
                "preco_sem_desconto_acesso_redes": 0.72,
                "preco_com_desconto_acesso_redes": 0.55
            },
            {
                "idUsage": "GMR-00016-02-1559510402",
                "idSubUsage": "GMR-00016-02-1559510402-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-06-02T22:19:48.000Z",
                "stopTimestamp": "2019-06-02T23:26:10.000Z",
                "idNetworkOperator": "MOBI",
                "energia_total_periodo": 44.67,
                "preco_opc": 0,
                "preco_unitario_opc_ativacao": 0,
                "preco_unitario_opc_energia": 0,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0,
                "preco_opc_energia": 0,
                "preco_opc_tempo": 0,
                "energia_vazio": 44.67,
                "energia_fora_vazio": 0,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0,
                "preco_sem_desconto_acesso_redes_vazio": 1.87,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0,
                "preco_com_desconto_acesso_redes_vazio": 1.87,
                "preco_com_desconto_acesso_redes_fora_vazio": 0,
                "desconto_acesso_redes": 0,
                "preco_sem_desconto_acesso_redes": 1.87,
                "preco_com_desconto_acesso_redes": 1.87
            },
            {
                "idUsage": "AZB-00001-03-1559229859",
                "idSubUsage": "AZB-00001-03-1559229859-1",
                "idExternalNumber": "PTEVAZ00000010000093002",
                "startTimestamp": "2019-05-30T16:24:06.000Z",
                "stopTimestamp": "2019-05-30T16:34:18.000Z",
                "idNetworkOperator": "GLPG",
                "energia_total_periodo": 0.611,
                "preco_opc": 0.59,
                "preco_unitario_opc_ativacao": 0.489,
                "preco_unitario_opc_energia": 0.162,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0.49,
                "preco_opc_energia": 0.1,
                "preco_opc_tempo": 0,
                "energia_vazio": 0,
                "energia_fora_vazio": 0.611,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.0245,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0813,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.01,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0.06,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.05,
                "desconto_acesso_redes": 0.01,
                "preco_sem_desconto_acesso_redes": 0.06,
                "preco_com_desconto_acesso_redes": 0.05
            },
            {
                "idUsage": "VNF-00004-03-1548577026",
                "idSubUsage": "VNF-00004-03-1548577026-1",
                "idExternalNumber": "PTEVAZ00000010000093001",
                "startTimestamp": "2019-01-27T08:17:06.000Z",
                "stopTimestamp": "2019-01-27T08:47:23.000Z",
                "idNetworkOperator": "GLPG",
                "energia_total_periodo": 5.244,
                "preco_opc": 1.34,
                "preco_unitario_opc_ativacao": 0.489,
                "preco_unitario_opc_energia": 0.162,
                "preco_unitario_opc_tempo": 0,
                "preco_opc_ativacao": 0.49,
                "preco_opc_energia": 0.85,
                "preco_opc_tempo": 0,
                "energia_vazio": 0,
                "energia_fora_vazio": 5.244,
                "preco_unitario_acesso_redes_vazio": 0.0419,
                "preco_unitario_acesso_redes_fora_vazio": 0.1058,
                "desconto_unitario_acesso_redes_vazio": 0,
                "desconto_unitario_acesso_redes_fora_vazio": 0.036,
                "preco_unitario_com_desconto_acesso_redes_vazio": 0.0419,
                "preco_unitario_com_desconto_acesso_redes_fora_vazio": 0.0698,
                "desconto_acesso_redes_vazio": 0,
                "desconto_acesso_redes_fora_vazio": 0.19,
                "preco_sem_desconto_acesso_redes_vazio": 0,
                "preco_sem_desconto_acesso_redes_fora_vazio": 0.55,
                "preco_com_desconto_acesso_redes_vazio": 0,
                "preco_com_desconto_acesso_redes_fora_vazio": 0.36,
                "desconto_acesso_redes": 0.19,
                "preco_sem_desconto_acesso_redes": 0.55,
                "preco_com_desconto_acesso_redes": 0.36
            }
        ]
        
        const properData = onData(mockData)
        setData(properData)

    }, [])

    const onData = (response) => {
        const table = {}

        const insertRow = (row, _year, _month) => {

            const getMonthName = (__month) => {
                switch (__month) {
                    case 1:
                        return `${appState.i18n.all.months.jan}`
                    case 2:
                        return `${appState.i18n.all.months.feb}`
                    case 3:
                        return `${appState.i18n.all.months.mar}`
                    case 4:
                        return `${appState.i18n.all.months.apr}`
                    case 5:
                        return `${appState.i18n.all.months.may}`
                    case 6:
                        return `${appState.i18n.all.months.jun}`
                    case 7:
                        return `${appState.i18n.all.months.jul}`
                    case 8:
                        return `${appState.i18n.all.months.aug}`
                    case 9:
                        return `${appState.i18n.all.months.sep}`
                    case 10:
                        return `${appState.i18n.all.months.oct}`
                    case 11:
                        return `${appState.i18n.all.months.nov}`
                    case 12:
                        return `${appState.i18n.all.months.dec}`
                }
            }

            const monthName = getMonthName(_month)

            if (table[_year]) {
                if (!table[_year][monthName]) {
                    table[_year][monthName] = []
                }
            } else {
                table[_year] = {}
                table[_year][monthName] = []
            }
            table[_year][monthName].push(row)
        }


        response.forEach(row => {
            const newRow = { ...row }
            const start = new Date(row.startTimestamp)
            const stop = new Date(row.stopTimestamp)
            const year = start.getFullYear()
            const month = start.getMonth() + 1
            newRow.startTimestamp = start
            newRow.stopTimestamp = stop

            insertRow(newRow, year, month)
        })

        
        
        // console.log('====================================');
        // console.log('table', table);
        // console.log('====================================');

        
        return table
    }


    return (
        <Container>
            <ConsumptionList data={data}>

            </ConsumptionList>
        </Container>
    )
}
