import React from 'react'
import { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../../../contexts/AppContext'
import { shadow } from '../../../../styles/theme'

const Row = styled.div`
    ${shadow}
    background-color: ${props => props.theme.default.colors.secondary};
    padding: 34px;
    border-radius: 10px;
    margin: 20px 0;
`

const Table = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    justify-content: center;
`

const DateColumn = styled(Column)`
    min-width: 160px;
`

const ColumnTitle = styled.span`
    font-weight: 500;
    font-size: 8pt;
    text-transform: uppercase;
    color: #a7a7a7;
`

const ColumnText = styled.div`
    margin-top: 5px;
`

const DateColumnTitle = styled(ColumnTitle)`
    font-size: 14pt;
`

const DateColumnDetails = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
`

const DateColumnStartTime = styled.div`
    font-size: 11pt;
`

const DateColumnDuration = styled.div`
    font-size: 11pt;
`



export default function ConsumptionStripe({ data }) {

    const { appState } = useContext(AppContext);
    const mockPriceVazio = 0.17
    const mockPriceForaVazio = 0.22

    const roundNumber = (number) => {
        return Math.round((number + Number.EPSILON) * 100) / 100
    }

    const formatNumber = (number) => {
        return Intl.NumberFormat(navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number)
    }


    const getDate = (_data) => {
        if (_data.startTimestamp)
            return _data.startTimestamp.toLocaleDateString("PT")
    }

    const getTime = (_data) => {

        const checkDigits = (value) => {
            const string = value.toString()
            if (string.length < 2) {
                return `0${string}`
            }
            return string
        }

        const date = _data.startTimestamp
        if (date) {
            const seconds = checkDigits(date.getSeconds());
            const minutes = checkDigits(date.getMinutes());
            const hour = checkDigits(date.getHours());

            return `${hour}:${minutes}:${seconds}`
        }
    }

    const getDuration = (_data) => {
        if (_data && _data.startTimestamp && _data.stopTimestamp) {

            function getTimeDisplay(millisec) {
                let h, m, s;
                h = Math.floor(millisec / 1000 / 60 / 60);
                m = Math.floor((millisec / 1000 / 60 / 60 - h) * 60);
                s = Math.floor(((millisec / 1000 / 60 / 60 - h) * 60 - m) * 60);
                s < 10 ? s = `0${s}` : s = `${s}`
                m < 10 ? m = `0${m}` : m = `${m}`
                h < 10 ? h = `0${h}` : h = `${h}`

                if (h !== "00") {
                    return `${h}h${m}m${s}s`
                } else if (h === "00" && m !== "00") {
                    return `${m}m${s}s`
                } else {
                    return `${s}s`
                }
            }

            const start = _data.startTimestamp
            const end = _data.stopTimestamp

            const duration_miliseconds = end - start


            return getTimeDisplay(duration_miliseconds)


        }
    }

    const getCard = (_data) => {
        if (data.idExternalNumber)
            return data.idExternalNumber.slice(-3)
    }

    const getOPC = (_data) => {
        if (data.idNetworkOperator)
            return data.idNetworkOperator
    }

    const getEnergy = (_data) => {
        if (data.energia_total_periodo >= 0)
            return data.energia_total_periodo
    }

    const getEnergyCost = (_data) => {
        if (data.energia_vazio >= 0 && data.energia_fora_vazio >= 0) {
            const calculation = parseFloat(data.energia_vazio) * mockPriceVazio + parseFloat(data.energia_fora_vazio) * mockPriceForaVazio
            const value = roundNumber(calculation)
            return value
        }
    }

    const getOPCCost = (_data) => {
        if (data.preco_opc >= 0) {
            const value = roundNumber(data.preco_opc)
            return value
        }
    }

    const getTaxes = (_data) => {

        const getTAR = () => {
            if (data.preco_com_desconto_acesso_redes >= 0) {
                const value = roundNumber(data.preco_com_desconto_acesso_redes)
                return value
            }
        }

        const getIVA = () => {
            const iva = 0.23
            const energyCost = getEnergyCost(_data)
            const opcCost = getOPCCost(_data)
            const iec = getIEC()
            const EGME = getEGME()
            const tar = getTAR()

            return roundNumber((energyCost + opcCost + iec + EGME + tar) * iva)

        }

        const getIEC = () => {
            const iecRate = 0.001
            const kWConsumed = getEnergy(_data)
            return kWConsumed * iecRate
        }

        const getEGME = () => {
            if (_data && _data.startTimestamp && _data.startTimestamp > new Date("05/01/2020")) {
                return 0.1657
            } else {
                return 0
            }
        }

        return getIVA()

    }

    const getTotal = (_data) => {
        const energyCost = getEnergyCost()
        const opcCost = getOPCCost()
        const taxes = getTaxes()

        return roundNumber(energyCost + opcCost + taxes)
    }


    return (
        <Row>
            <Table>
                <DateColumn>
                    <DateColumnTitle>{getDate(data)}</DateColumnTitle>
                    <DateColumnDetails>
                        <ColumnTitle>{appState.i18n.account.columns.start}</ColumnTitle>
                        <DateColumnStartTime>{getTime(data)}</DateColumnStartTime>
                    </DateColumnDetails>
                    <DateColumnDetails>
                        <ColumnTitle>{appState.i18n.account.columns.duration}</ColumnTitle>
                        <DateColumnDuration>{getDuration(data)}</DateColumnDuration>
                    </DateColumnDetails>
                </DateColumn>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.card}</ColumnTitle>
                    <ColumnText>{getCard(data)}</ColumnText>
                </Column>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.opc}</ColumnTitle>
                    <ColumnText>{getOPC(data)}</ColumnText>
                </Column>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.energy}</ColumnTitle>
                    <ColumnText>{`${formatNumber(getEnergy(data))} kWh`}</ColumnText>
                </Column>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.energyCost}</ColumnTitle>
                    <ColumnText>{`€${formatNumber(getEnergyCost(data))}`}</ColumnText>
                </Column>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.opcCost}</ColumnTitle>
                    <ColumnText>{`€${formatNumber(getOPCCost(data))}`}</ColumnText>
                </Column>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.taxes}</ColumnTitle>
                    <ColumnText>{`€${formatNumber(getTaxes(data))}`}</ColumnText>
                </Column>
                <Column>
                    <ColumnTitle>{appState.i18n.account.columns.total}</ColumnTitle>
                    <ColumnText>{`€${formatNumber(getTotal(data))}`}</ColumnText>
                </Column>
            </Table>
            {/* {JSON.stringify(data)} */}
        </Row>
    )
}
