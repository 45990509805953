import React from 'react'
import styled from 'styled-components'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { SET_USER } from '../../../reducers/AppReducer';
import Loading from '../../common/Loading';

const Wrapper = styled.div`
    margin-right: 200px;
`

const LoadingWrapper = styled.div`
    height: 80vh;
    width: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function Grid() {

    const { appState, dispatch } = useContext(AppContext);
    const [data, setData] = useState([])

    const apiUrl = process.env.GATSBY_CEMEAPI_ENDPOINT;
    // const apiUrl = 'http://localhost:9000';

    useEffect(() => {
        try {


            const getData = async () => {
                const options = {

                }
                const response = await axios.get(`${apiUrl}/api/cards`, {
                    params: {
                        page: 0,
                        size: 20,
                    },
                    headers: {
                        authorization: appState.user.accessToken,
                        refresh_token: appState.user.refreshToken
                    }
                })
                if (response.headers.access_token && response.headers.refresh_token) {
                    dispatch({ type: SET_USER, payload: { ...appState.user, accessToken: response.headers.access_token, refreshToken: response.headers.refresh_token } })
                }
                setData(response.data.cards)
            }
            getData()
        } catch (e) {
            console.error(e)
        }
    }, [])


    const defaultColDef = {
        filter: true,
        resizable: true,
        sortable: true
    }

    const dateFilterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
                return 0;
            }

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date

            const cellDate = new Date(dateAsString);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        }
    };

    const getDate = params => {
        const dateString = params.value
        const year = dateString.substr(0, 4)
        const month = dateString.substr(5, 2)
        const day = dateString.substr(8, 2)
        const hour = dateString.substr(11, 2)
        const min = dateString.substr(14, 2)
        const sec = dateString.substr(17, 2)

        // console.log(`${year}-${month}-${day} ${hour}:${min}:${sec}`)
        return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    }

    return (
        <Wrapper>
            <div className="ag-theme-alpine" style={{ paddingTop: '70px', height: 'calc(100vh - 70px)' }}>
                {data.length > 0 ?
                    <AgGridReact rowData={data} defaultColDef={defaultColDef}>
                        <AgGridColumn headerName="Nome Cartão" field="Nome_Cartao"></AgGridColumn>
                        <AgGridColumn headerName="Matrícula" field="License Plate Number"></AgGridColumn>
                        <AgGridColumn headerName="ID Cartão" field="idInternalNumber_C"></AgGridColumn>
                        <AgGridColumn headerName="Carro - Marca" field="Make"></AgGridColumn>
                        <AgGridColumn headerName="Carro - Modelo" field="Model"></AgGridColumn>
                        <AgGridColumn headerName="Estado Cartão" field="Status_Cartao"></AgGridColumn>
                    </AgGridReact>
                    : <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>}
            </div>
        </Wrapper>
    )
}
