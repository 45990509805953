import React, { useEffect } from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';
import Grid from '../../components/layout/account/Grid';
import CardsGrid from '../../components/layout/account/CardsGrid';
import { SideTab } from '../../components/layout/account/SideTab';
import { AppContext } from '../../contexts/AppContext';
import { SET_PAGE } from '../../reducers/AccountPageReducer';
import AccountDataVis from '../../components/layout/account/AccountDataVis';
import { AccountPageContext } from '../../contexts/AccountPageContext';
import { shadow } from '../../styles/theme';
import { navigate } from 'gatsby';
import Loading from '../../components/common/Loading';


const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const Sidebar = styled.div`
    ${shadow}
    width: 200px;
    height: auto;
    left: 0;
    padding-top: 70px;
`

const GridContainer = styled.div`
    width: calc(100% - 200px);
    right: 0;
    height: 100%;
`

const TabContainer = styled.div`
    height: 70px;
    width: 100%;
`

export default function Account() {

    const { appState } = useContext(AppContext);
    const { accountContext } = useContext(AccountPageContext);
    const [account, accountDispatch] = accountContext;
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!appState.user) {
            navigate("/login")
        } else {
            setLoading(false)
        }
        return () => {}
    }, [appState.user])

    const renderPage = (page) => {
        switch(page) {
            case "Consumos":
                return <Grid />
            case "Cards": 
                return <CardsGrid />
            case "Data":
                return <AccountDataVis />
        }
    }

    return (
        <Layout>
             {loading ? <Loading /> :<Container>
                <Sidebar>
                    <SideTab id="Consumos" name={"Consumos"} onClick={() => accountDispatch({type: SET_PAGE, payload: "Consumos" })}  /> 
                    <SideTab id="Cards" name={"Cartões"} onClick={() => accountDispatch({type: SET_PAGE, payload: "Cards" })} /> 
                    {/* <SideTab id="Data"name={"Carregamentos"} onClick={() => accountDispatch({type: SET_PAGE, payload: "Data" })} />  */}
                </Sidebar>
                <GridContainer>
                    {renderPage(account.page)}
                </GridContainer>
            </Container>}
        </Layout>
    )
}
